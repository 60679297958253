import React from "react"
import GlobalStyle from "../components/GlobalStyle"
import MenuBar from "../components/layout/MenuBar"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { useCabins } from "../hooks/useCabins"
import { device } from "../utils/mediaQueries"
import { Helmet } from "react-helmet"
import { CityCategories } from "../components/cityCategories/CityCategories"
import DatePickerComponent from "../components/datePickerComponent/DatePickerComponent"
import { CvAd } from "../components/ads/cvAd"
import { OmaurakkaAd } from "../components/ads/omaUrakkaAd"

const Container = styled.div`
  padding: 0rem 1rem;
`

const Header1 = styled.h1`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  padding: 5px 20px;

  color: white;
  @media ${device.mobileL} {
    padding: 0.1rem;
    font-size: 35px;
  }
`
const Header2 = styled.h2`
  font-size: 27px;
  text-align: center;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 5px 20px;
  color: white;
`

const ResultHeader = styled.h3`
  font-size: 22px;
  font-weight: 200;
  text-align: center;
`

const AdHeader = styled.h4`
  color: blue;
  font-weight: 800;
`

const AdText = styled.div``

const AdContainer = styled.div`
  background: #eaeaea;
  padding: 2rem;
  border-radius: 20px;
`

const Background = styled(BackgroundImage)`
  margin-bottom: 30px;
  padding-bottom: 30px;
`

const TextArea = styled.div``

const RegionsContainer = styled.div`
  margin: 2rem;
`

const TextContainer = styled.div`
  padding: 8rem 16rem;
  @media ${device.tablet} {
    padding: 1rem;
  }
`

const IndexPage = ({ data }) => {
  const imageData = data.file.childImageSharp.fluid
  const cabinsHook = useCabins(data.allMokitJson.nodes)
  const regions = data.allMarkdownRemark.edges
  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Mökkivuokraus Suomi - Edulliset vuokramökit - Parhaat tarjoukset!
        </title>
        <meta
          name="description"
          content="Tarvitseko majoitusta Suomessa? Mökki on mitä mainioin tapa majoittua ympäri Suomen ja vuokramökki tuo lomaasi aivan uusia ulottuvuuksia. Katso täältä kattava tarjonta mökkejä ja varaa heti sinun oma suosikkisi."
        />
        <html lang="fi" />
        <meta
          name="google-site-verification"
          content="x-ZcXFv4LaRPhhDlujG6TBU0r2aCvQwhSgKJOfPar88"
        />
        <meta
          name="ahrefs-site-verification"
          content="70c2df0767f58b1cb62a5efe748c22711d61e9f1d3d1bc64aa6e0f0f5e393873"
        ></meta>
      </Helmet>
      <Background Tag="section" fluid={imageData} backgroundColor={`#040e18`}>
        <MenuBar items={data.allMarkdownRemark.edges} />
        <Container>
          <Header1>Etsi sopiva vuokramökki Suomesta</Header1>
          <Header2>Tutustu tuhansiin eri mökkeihin alapuolelta</Header2>
          <DatePickerComponent
            urlFix=""
            filter=""
            name="Suomi"
            isRegion={false}
            bookingId={null}
          />
        </Container>
      </Background>
      {cabinsHook.isLoading ? <TextArea>Tekstiä</TextArea> : ""}
      <OmaurakkaAd />
      <ResultHeader>Tutustu vuokramökkeihin maakunnittain</ResultHeader>
      <RegionsContainer>
        <CityCategories cities={regions} />
      </RegionsContainer>

      <CvAd />
      <TextContainer>
        <AdContainer>
          <AdHeader>
            Milloin olet viimeksi kilpailuttanut kuukausittaiset kulusi?
          </AdHeader>
          <AdText>
            <p>
              <b>Perheen puhelinliittymät</b>
            </p>
            <p>
              Puhelinliittymät ovat perinteisesti kuukausittainen kulu meille
              jokaiselle. Milloin olet itse viimeksi kilpailuttanut
              puhelinliittymäsi. Tee se nyt osoitteessa{" "}
              <a
                href="https://www.kilpailutapuhelinliittyma.fi/"
                target="_blank"
              >
                kilpailutapuhelinliittyma.fi
              </a>
            </p>
          </AdText>
          <AdText>
            <p>
              <b>Sähkösopimus</b>
            </p>
            <p>
              Myös sähkösopimus on pakollinen kuluerä joka kuukausi. Kannattaa
              kuitenkin varmistaa ettei siitä maksa turhaan liian suurta maksua.
              <a href="https://www.kilpailutasahkosopimus.fi/" target="_blank">
                Kilpailuta siis sähkösopimus osoitteessa
                kilpailutasahkosopimus.fi
              </a>
            </p>
          </AdText>
        </AdContainer>
        <h2>Vuokramökit</h2>
        <p>
          <br />
        </p>
        <p>
          Kotimaanmatkailu on nostanut viimeaikoina huomattavasti suosiotaan,
          eikä suotta sillä kotimaamme Suomi tarjoaa upeita matkailuelämyksiä
          ympäri vuoden. Kesällä kaupunki kuin kaupunki herää loistoonsa ja
          kukapa ei rakastaisi Porvoota tai Turun saaristoa.
        </p>
        <p>
          Talvella taas Lappi tarjoaa elämyksiä, joita tullaan kokemaan ympäri
          maailman. Katso vaikka uutiset viimevuosien julkkiksista ketkä ovat
          olleet ihailemassa lumisia maisemia tai revontulia
          <strong> Levillä, Rovaniemellä, Ivalossa</strong> ja muualla Lapissa.
        </p>
        <p>
          Mikäpä olisikaan mukavampi tapa majoittua näillä kotimaan matkoilla
          kuin vuokramökit.
          <strong>
            Vuokramökki on yksi parhaista tavoista majoittua Suomessa
          </strong>
          , sillä kukapa meistä ei rakasta mökissä oleskelua. Mökit tarjoavat
          juuri sen viimeisen silauksen kotimaan matkalle, sillä kukapa ei
          nauttisi mökin tunnelmallisesta takkatulesta rankan päivän jälkeen.
        </p>
        <p>
          Parasta on, että vuokramökit löytyvät ympäri Suomea ja matkustitpa
          missä tahansa niin voit lähes aina löytää majoittumiseen
          vuokramökkejä. Varaamalla mökin saat varmasti aikaan unohtumattoman
          loman matkustitpa sitten missä päin tahansa Suomea.
        </p>
        <p>
          Tämä sivusto auttaa sinua löytämään sinulle parhaat vuokramökit
          jokaisesta Suomen kolkasta. Alapuolella esittelemme sinulle nopeasti
          parhaat kotimaan matkakohteet jokaisesta Suomen maakunnasta. Tutustu
          huolella jokaiseen kohteeseen ja lähde kokeilemaan mökkeilyä
          kotimaamme upeissa maisemissa.
        </p>
        <p>
          <br />
        </p>
        <h2>Parhaat vuokramökit Suomessa</h2>
        <h4>Ahvenanmaa</h4>
        <p>
          <Link to="/ahvenanmaa">Ahvenanmaa</Link> on varmasti yksi
          ikumuistoisempia matkakohteita Suomen kesässä. Tämä ihastuttava saari
          lumoaa sen kävijät lukemattomilla pikkusaarilla, joita pitkin voit
          saapua itse Ahvenanmaan pääsaarelle. Siellä matkailijoita odottavat
          pienet punertavat maantiet, joita voit kierrellä niin autolla,
          moottoripyörällä kuin vaikka polkypyörälläkin. Ahvenanmaa ja sitä
          ympäröivä saaristo onkin unelmakohde pyöräilyn ystävälle ja mikäpä
          sopisi paremmin pyöräilijän majoittumiseen kuin oma rauhallinen
          vuokramökki. Ahvenanmaalta löydät monet eri vuokramökit, joista löytyy
          varmasti sopiva mökki ihan kenelle tahansa.
        </p>
        <h4>Etelä-Pohjanmaa</h4>
        <p>
          <Link to="/etela-pohjanmaa">Etelä-Pohjanmaa</Link> tarjoaa tekemistä
          koko perheelle ja siellä sijaitseekin monia kuuluisia nähtävyyksiä.
          Näistä tämän hetken puhutuin lienee{" "}
          <a
            href="https://www.ahtarizoo.fi/index.php/fi/"
            rel="nofollow"
            target="_blank"
          >
            Ähtäri Zoo
          </a>
          , josta löydät suomen ainoat <strong>Pandat</strong>. Tämä on
          ehdottomasti vierailemisen arvoinen paikka ainakin kerran elämässä.{" "}
          <Link to="/etela-pohjanmaa/ahtari">Ähtärin lähistöltä</Link> löydät
          useita vuokramökkejä, joista on helppo lähteä tutustumaan Ähtäri
          Zoohon sekä alueen muihin nähtävyyksiin kuten{" "}
          <a href="https://www.powerpark.fi/" rel="nofollow" target="_blank">
            Powerpark
          </a>
          ,{" "}
          <a href="https://www.tuuri.fi/" rel="nofollow" target="_blank">
            Kyläkauppa Veljekset keskinen
          </a>{" "}
          tai varsinkin perheen pienimmille sopivaan{" "}
          <a href="https://www.dap.fi/seinajoki" rel="nofollow" target="_blank">
            Duudsonit Action parkkiin.
          </a>
          Etsi siis sopivia mökkejä Ähtärin lähistöltä ja sinulla on koko
          Etelä-Pohjanmaa käsissäsi.
        </p>
        <h4>Etelä-Karjala</h4>
        <p>
          Itärajalla sijaitseva <Link to="/etela-karjala">Etelä-Karjala</Link>
          on mitä mainioin kesäkohde ja löytyyhän sieltä myös mainio talvikohde
          <Link to="/etela-karjala/himos">Himos</Link>. Etelä-Karjalan suosituin
          sekä kuuluisin kohde lienee kuitenkin{" "}
          <Link to="/etela-karjala/lappeenranta">Lappeenranta</Link>, jossa voi
          viettää helposti päivän jos toisenkin. Vaikka Etelä-Karjala tarjoaa
          muitakin upeita kaupunkeja, kuten Imatra ja Taipalsaari niin meidän
          mielestämme ehdottomasti paras vaihtoehto lähteä lomailemaan
          Etelä-Karjalaan on ottaa mökki hieman syrjemmästä ja lähteä nauttimaan
          tämän seudun upeasta luonnosta. Löytyyhän sieltä maamme suurin järvi
          Saimaa ja mikäpä sen parempi majoituspaikka kuin vuokramökki Saimaan
          rannalla omalla saunalla. Vuokraa parhaat vuokramökit Etelä-Karjalasta
          tämän sivuston kautta ja lähde nauttimaan unohtumattomasta lomasta
          Suomen kauniiseen luontoon.
        </p>
        <h4>Etelä-Savo</h4>
        <p>
          <Link to="/etela-savo">Etelä-Savo</Link> monia mukavia kohteita ja
          myös vuokramökit on helppo löytää tältä alueelta ja miksipä ei olisi,
          sillä tämä maakunta on yksi kotimaamme järivisimmistä kunnista. Suomen
          kesä, järvi , sauna ja mökki on lyömätön yhdistelmä jota on vaikea
          kokea Suomen ulkopuolella. Me suosittelemme etsimään mökkejä
          esimerkisi <Link to="/etela-savo/mikkeli">Mikkelin</Link> tai
          <Link to="/etela-savo/pieksamaki">Pieksämäen</Link> läheltä, joista
          molemmat kaupungit ovat ehdottomasti myös vierailemisen arvoisia.
          Etelä-Savosta puhuttaessa ei voida myöskään sivuuttaa Savonlinnaa,
          joka on varmasti yksi kotimaamme ihastuttavimmista kesäkaupungeista.
          Täläkin alueella vuokramökit tarjoavat helppoa tapaa tutustua alueen
          upeaan luontoon ja vaikka maamme suurimpaan järveen Saimaaseen.
        </p>
        <h4>Kainuu</h4>
        <p>
          <Link to="/kainuu">Kainuu</Link> on maakunta, jossa alkaa jo aistimaan
          että on saapumassa pohjoiseen Suomeen. Kainuu toimii matkakohteena
          niin kesällä kuin talvellakin. Talvella sen ehdottomana helmenä toimii
          <Link to="/kainuu/vuokatti">Vuokatti</Link>, joka tarjoaa monia eri
          aktiviteettejä talvilajien ystäville. Vuokatin lähellä sijatsee
          lukemattomia mökkejä, joilla varmistat sopivan majoituspaikan
          isommallekin porukalle. Kainuuseen kannattaa kuitenkin tutustua myös
          kesäisin ja esimerkiksi{" "}
          <a
            href="https://www.luontoon.fi/hossanluontokeskus"
            rel="nofollow"
            target="_blank"
          >
            Hossan luonnonpuista
          </a>{" "}
          on matkakohde, johon jokaisen suomalaisen tulisi jossain vaiheessa
          tutustua paremmin.
        </p>
        <h4>Keski-Suomi</h4>
        <p>
          <Link to="/keski-suomi">Keski-Suomi</Link> on kuuluisa tuhansisa
          kesämökkeilijöistään ja eivätkä he ole väärässä valitessaan juuri
          tämän maakunnan kesämökkiensä paikaksi. Jos kesämökit sopivat
          mainiosti Keski-Suomeen, niin täysin samanlailla sinne sopivat
          mainiosti myös lomamökit. Keski-Suomen keskuksena toimii{" "}
          <Link to="/keski-suomi/jyvaskyla">Jyväskylä</Link>, mutta myös
          pienemmät paikkakunnat kuten vaikka Jämsä tarjoaa palan kauneinta
          Suomeamme. Keski-Suomesta löydät lukuisia järviä ja ihania
          kanssallispuistoja, kuten esimerkiksi{" "}
          <a
            href="https://www.luontoon.fi/pyha-hakki"
            rel="nofollow"
            target="_blank"
          >
            Pyhä- häkin kanssallispuisto
          </a>
          . Tällä alueella mökkejä löytyy joka niemen notkosta, joten jokainen
          löytää varmasti sopivan majoituskohteen jossa voi todenteolla nauttia
          Suomen kesästä.
        </p>
        <h4>Keski-Pohjanmaa</h4>
        <p>
          <Link to="/keski-pohjanmaa">Keski-Pohjanmaa</Link> sijaitsee Perämeren
          rannalla ja tarjoaa merellistä tunnelmaa kotimaan lomalle. Sen
          suosituimpia alueita ovat Kokkola ja Kaustinen, joista molemmat
          sopivat oikein mainiosti mökkeilyyn. Varsinkin kesällä Keski-Pohjanmaa
          tarjoaa myöskin upeita merimaisemia ja lähellä sijaisevat muunmuassa
          <a href="https://visitkalajoki.fi/" rel="nofollow" target="_blank">
            Kalajoen upeat hiekkarannat
          </a>
          , jotka vetävät vertoja monille kuuluisille hiekkarannoille Suomen
          ulkopuolella. Mikäli merivesi tuntuu liian viileältä niin alueelta
          löytyy myös mainio &nbsp;Kokkolan Uintikeskus Vesiveijari, jossa
          viihtyvät niin perheen pienimmät kuin myös vähän isommatkin.
        </p>
        <h4>Kanta-Häme</h4>
        <p>
          <Link to="/kanta-hame">Kanta-Häme</Link> on pääkaunkiseutua lähellä
          sijaitseva matkakohde, joka herää henkiin varsinkin kesäisin. Alueen
          suosikki kohteena toimii{" "}
          <Link to="/kanta-hame/hameenlinna">Hämeenlinna</Link>, joka tarjoaa
          monia eri nähtävyyksiä kuteen{" "}
          <a
            href="https://www.kansallismuseo.fi/fi/haemeenlinna/etusivu"
            rel="nofollow"
            target="_blank"
          >
            Hämeen Linnan
          </a>
          ,{" "}
          <a
            href="https://www.panssarimuseo.fi/"
            rel="nofollow"
            target="_blank"
          >
            panssarimuseon
          </a>{" "}
          sekä{" "}
          <a
            href="https://www.luontoon.fi/aulanko"
            rel="nofollow"
            target="_blank"
          >
            Aulangon luonnonpuiston
          </a>
          . Muita suosittuja kohteita Kanta-Hämeessä ovat muunmuassa Forssa sekä
          Riihimäki. Tältä seudulta löytyy myös mökkejä aivan jokaiseen makuun
          ja meidän mielestämme se on myös ehdottomasti paras tapa tutustua
          Kanta-Hämeeseen. Katso sivuiltamme juuri sinulle sopivin majoitus ja
          lähde tutustumaan Kanta-Hämeeseen jo tänä kesänä.
        </p>
        <h4>Lappi</h4>
        <p>
          Jos pitäisi mainita Suomen ainutlaatuisin maakunta, niin monen vastaus
          olisi varmasti <Link to="/lappi">Lappi</Link> ja aivan syystä. Tämä
          maakunta niittää mainetta maailman ympäri ja sitä tulevat katsomaan
          jopa kuuluisuudet joka puolelta maailmaan. Lappi on myöskin
          maapinta-alaltaan Suomen suurin maakunta, joten tekemistä ja nähtävää
          siellä riittää useammankin matkan verran. Lisäksi Lappi ei ole
          pelkästään talvimatkailukohde ja vaan Lapin kesä on myös täysin
          ainutlaatuinen kokemus yöttömine öineen. Lapista löytyy lukemattomia
          matkakohteita, joita suositella mutta suosituimpia ovat{" "}
          <Link to="/lappi/rovaniemi">Rovaniemi</Link>,{" "}
          <Link to="/lappi/levi">Levi</Link>,{" "}
          <Link to="/lappi/inari">Inari</Link>,{" "}
          <Link to="/lappi/saariselka">Saariselkä</Link> sekä{" "}
          <Link to="/lappi/yllas">Ylläs</Link>. Nämä ovat kuitenkin vain
          pintaraapaisu Lappiin ja listaa voisikin jatkaa lähes loputtomiin.
          Lappi on myöskin mökkien valtakuntaa ja emme voi muuta kuin suositella
          että Lapin lomallasi majoitut mökissä, sillä se vie sinut majoittumaan
          suoraan Lapin kauneimpaan luontoon. Voit varata kaikki parhaat
          lomamökit Lappiin meidän sivustomme kautta.
        </p>
        <h4>Päijät-Häme</h4>
        <p>
          <Link to="/paijathame">Päijät-Häme</Link> sijaitsee pääkaunkiseudun
          vieressä ja onkin helppo lomakohde saapua monin eri liikennevälein.
          Päijät-Hämeen keskuksena toimii Vesijärven rannalla sijaitseva{" "}
          <Link to="/paijathame/lahti">Lahti</Link>, joka tarjoaa monipuolista
          tekemistä vuoden ympäri. Haluatko lähteä nauttimaan talvella
          Salpausselän kisoista tai laskettelemaan{" "}
          <a href="https://www.messila.fi/fi/" rel="nofollow" target="_blank">
            Messilään
          </a>{" "}
          vai tahdotko nauttia Suomen kesästä Lahden satamassa tai maistella
          torilla legendaarista kebabmukia? Lahdessa kaikki tämä onnistuu ja
          parhaiten tämä toimii kun tämän jälkeen pääset rauhoittumaan omaan
          vuokramökkiisi, joita löytyy kiitettävästi Lahden seudun alueelta.
          Päijät-Häme ei ole kuitenkaa pelkästään Lahti vaan siellä riittää
          nähtävää myös muualla. Lahden vieressä sijaitseva{" "}
          <Link to="/paijathame/nastola">Nastola</Link> tarjoaa mahtavat
          puitteet mökkeilyyn ja Vääksyn kanava on jokaisen nähtävä ainakin
          kerran kesässä. Varaa helposti mökkisi seuraavalla lomalle sivustomme
          kautta ja lähde tutustumaan Päijät-Hämeeseen.
        </p>
        <h4>Pirkanmaa</h4>
        <p>
          <Link to="/pirkanmaa">Pirkanmaa</Link> on oikea luonnonystävän
          unelmakohde ja sieltä löytyvät järvet, vaarat ja joen uumat sulattavat
          kovemmankin sydämmen. Pirkanmaa suosituin kohde on ehdottomasti
          <Link to="/pirkanmaa/tampere">Tampere</Link> ja miksipä ei olisi,
          sillä se jaksaa ihastuttaa vierailijansa kerta toisensa jälkeen.
          <a href="https://sarkanniemi.fi/" rel="nofollow" target="_blank">
            Särkänniemi
          </a>
          ,
          <a
            href="https://sarkanniemi.fi/muut/nasinneula/"
            rel="nofollow"
            target="_blank"
          >
            {" "}
            Näsinneula
          </a>
          ,{" "}
          <a
            href="https://fi.wikipedia.org/wiki/Tammerkoski"
            rel="nofollow"
            target="_blank"
          >
            Tammerkoski
          </a>{" "}
          ja Hervanta ovat vain pintaraapaisu Tampereen tarjonnasta ja
          kaunpungista löytyykin tekemistä ihan kaikille. Ei kuitenkaan kannata
          jäädä vain Tampereelle vaan lähteä tutustumaan sitä ympäröivään upeaan
          luontoon ja myös pienempiin paikkakuntiin kuten{" "}
          <Link to="/pirkanmaa/lempaala">Lempäälä</Link> tai vaikka{" "}
          <Link to="/pirkanmaa/virrat">Virrat</Link>. Nämä kaikki kohteet
          sopivat mainioisti myös mökkeilyyn ja mökit tarjoavat helpon
          sisäänpääsyn Pirkanmaan upeaan luontoon. Vuokramökissä herätessä ja
          linnunlaulua kuunnellessa nousee väkisinkin hymy huulille.
        </p>
        <h4>Kymenlaakso</h4>
        <p>
          <Link to="/kymenlaakso">Kymenlaakso</Link> on maakunta, josta löytyy
          monia suosittuja kesäkaupunkeja sekä aina ihastuttava Kymijoki.
          Kymenlaakson suosituimpia kohteita lienevät kaupungit kuten{" "}
          <Link to="/kymenlaakso/kotka">Kotka</Link>,{" "}
          <Link to="/kymenlaakso/hamina">Hamina</Link>,{" "}
          <Link to="/kymenlaakso/pyhtaa">Pyhtää</Link> ja{" "}
          <Link to="/kymenlaakso/kouvola">Kouvola</Link>. Näistä etenkin Kotka
          ja Hamina tarjovat raikasta meritunnelmaa. Sen minkä Kouvola häviää
          merellisessä tunnelmassa, niin se voittaa tuottamalla Suomen parasta
          lakritsia. Etenkin Hamina on kuitenkin melko pieni paikkakunta, joten
          meidän mielestämme paras vaihtoehto Kymenlaaksoon tutustumiseen on
          vuokramökki esimerkiksi Kouvolan ta Kotkan lähistöltä, josta on sitten
          helppo lähteä tutustumaan lähiseutuun. Kymenlaakso ei myöskään unohda
          perheen pienimpiä vaan heille unohtumattomia tarjoaa Kouvolan
          lähistöllä sijaitseva{" "}
          <a href="https://www.tykkimaki.fi/" rel="nofollow" target="_blank">
            Tykkimäki
          </a>
          , jossa viettää helposti koko päivän. Ala siis jo suunnitelemaan
          seuraavaa lomaa ja ota kohteeksi Kymenlaakso.
        </p>
        <h4>Pohjanmaa</h4>
        <p>
          Alkaa peellä ja loppuu aalla, on niin hienoo olla Pohjanmaalla. Näin
          kertovat laulun sanat ja niissä on myös totuuden siemeniä sillä
          Pohjanmaalla on toden teola hienoa olla. Vielä hienompaa siitä tulee
          varatessasi juuri sinulle täydellisen mökin Pohjanmaalta.{" "}
          <Link to="/pohjanmaa">Pohjanmaa</Link>
          tarjoaa mukavaa ja leppoisaa merellistä tunnelmaa, jossa sielu lepää
          ja arjen murheet jäävät taakse. Vuokramökkien osalta Pohjanmaa tarjoaa
          monipuolista tarjontaa ja me pyrimme tarjoamaan sinulle sen parhaan
          mahdollisen majoituksen. Varaa mökki Kristiinankaupungin tai Seinäjoen
          läheltä ja sinulla on lähistölläsi lukemattomia mahdollisuuksia
          matkailuun.
        </p>
        <h4>Pohjois-Karjala</h4>
        <p>
          <Link to="/pohjoiskarjala">Pohjois-Karjala</Link> on ympärivuotinen
          matkailukohde, joka tarjoaa niin kaupunkilomaa kuin tutustumista sen
          jylhään luontoon. Suurimpana kaupunkina seudulla toimii Joensuu, joka
          on kuuluisa suositusta{" "}
          <a
            href="https://www.ilosaarirock.fi/2020/"
            rel="nofollow"
            target="_blank"
          >
            Ilosaarirockista
          </a>
          . Koko Pohjois-Karjala tarjoaa kuitenkin ilotuliksen lailla upeaa
          luontoa ja sen helmenä toimii lähes 350 metrin korkeuteen kohoava{" "}
          <Link to="/pohjoiskarjala/koli">Koli</Link>, joka tarjoaa mahtavia
          elämyksiä niin kesällä kuin talvellakin. Kolin lähistöltä löytyy myös
          pikkuinen <Link to="/pohjoiskarjala/lieksa">Lieksa</Link>, jonka
          lähistöltä suosittelemmekin etsimään sopivaa mökkiä. Kun on ensin
          kiivennyt Kolin hupuille ja nauttinut koko päivän sen upeista
          maisemista, niin ei ole mitään parempaa kuin palata lämpöseen mökkiin
          paistamaan makkaraa tai vaahtokarkkeja takkatulen ääreen.
          Pohjois-Karjala tarjoaa Kolin lisäksi myös upeita järvimaisemia sekä
          henkeäsalpaavia luonnonpuistoja. Mikäli nämä maisemat eivät ole vielä
          sinulle tuttuja, niin suosittelemme ehdottomasti harkitsemaan
          seuraavaa lomaa tälle suunnalle.
        </p>
        <h4>Pohjois-Pohjanmaa</h4>
        <p>
          <Link to="/pohjoispohjanmaa">Pohjois-Pohjanmaa</Link> antaa
          ensipuraisun Pohjoiseen-Suomeen ennen Lappiin saapumista. Tästä
          maakunnasta löydätkin monipuolisesti erilaisia kohteita kuten{" "}
          <Link to="/pohjoispohjanmaa/oulu">Oulu</Link>,{" "}
          <Link to="/pohjoispohjanmaa/kuusamo">Kuusamo</Link> tai sen vieressä
          sijaitseva <Link to="/pohjoispohjanmaa/ruka">Ruka</Link>. Mikä parasta
          nähtävää riittää ihan koko vuoden ajan ja Pohjois-Pohjanmaalle
          kannattaakin matkustaa niin kesäisin kuin talvisinkin, sillä
          vuodenajasta riippuen se tarjoaa aivan erilaisia kokemuksia. Mikäli
          Levi tuntuu jo pursuavan liikaa ihmisiä, niin Ruka on mitä mainoin
          valinta hieman rauhallisempaan talvilomaan ja vuokra mökit sieltä
          löytyvät pienelle tai isommallekin porukalle. Mökkien suhteen ei
          tarvitse pihtailla myöskään muualla Pohjois-Pohjanmaalla, vaan sopiva
          vuokramökki löytyy varmasti ympäri maakunnan. Mikäli siis Lappi tuntuu
          hieman liian kaukaiselta lomakohteelta, niin kannattaa lähteä ainakin
          ensiksi tutusttumaan Pohjois-Pohjanmaahan, jolla riittää myös
          valtavasti tarjottavaa.
        </p>
        <h4>Pohjois-Savo</h4>
        <p>
          Tekeekö mieli kalakukkoa? Ei sitten muuta kuin nokka kohti
          <Link to="/pohjoissavo">Pohjois-Savoa</Link> ja vierailu{" "}
          <Link to="/pohjoissavo/kuopio">Kuopioon</Link>, jossa eteesi taiotaan
          yksi jos toinenkin kalakukko. Pohjois-Savo ei ole kuitenkaan
          ainoastaan kalakukkoja ja Kuopiota, vaan maakunnasta löytyy myös
          paljon muuta mielenkiintoista tekemistä ja nähtävää. Talvilomalle
          sopivana matkakohteena toimii myös Tahko, jossa laskettelurinteet ja
          hiihtoladut odottavat vierailijaansa. Pohjois-Savo on myös täynnä
          pienempiä paikkakuntia, joiden suunnalta löytyy myöskin ihastuttavia
          lomamökkejä joista käsin seutua voisi kierrellä vaikka useita
          viikkoja. Pohjois-Savossa ei ole kuitenkaan pakko olla useaa viikkoa,
          vaan jo muutaman päivän loman aikana pääsee varmasti nauttimaan sen
          upeasta luonnosta ja savolaisten leppoisasta luonteesta. Avaa siis
          nettimökki ja varaa sopiva majoitus Pohjois-Savosta.
        </p>
        <h4>Satakunta</h4>
        <p>
          <Link to="/satakunta">Satakunta</Link> tarjoaa yhden palan kaunista
          länsi-rannikkoa ja sen suosituimpina kohteina toimivat{" "}
          <Link to="/satakunta/pori">Pori</Link> sekä{" "}
          <Link to="/satakunta/rauma">Rauma</Link>.{" "}
          <a
            href="https://fi.wikipedia.org/wiki/Yyteri"
            rel="nofollow"
            target="_blank"
          >
            Yyterin hiekkarannat
          </a>{" "}
          ovatkin pakollinen vierailukohden rantaloman ystäville ja{" "}
          <a href="https://porijazz.fi/fi/" rel="nofollow" target="_blank">
            Porin jazz
          </a>{" "}
          tarjoaa puolestaan unohtumattomia elämyksiä musiikin ystäville.
          Rantojen ja kaupunkien lisäksi Satakunta tarjoaa myöskien lukemattomia
          patikointireittejä ja upeita luontopolkuja. Satakuntaan matkustaessa
          kannattaakin ottaa ilo irti sen luonnosta yöpymällä mökissä hotellin
          sijaan. Mökin ollessa hyvällä sijainnilla sieltä on helppo vierailla
          vaikka Rauman vanhassa kaupungissa ja illalla palata nauttimaan mökin
          omasta rauhasta luonnon keskelle. Mikäli siis seuraavan loman olisi
          tarkoitus yhdistää niin kaupunki- kuin rantalomakin, niin Satakunta on
          mitä mainoin matkakohde tähän tarkoitukseen.
        </p>
        <h4>Varsinais-Suomi</h4>
        <p>
          <Link to="/varsinaissuomi">Varsinais-Suomi</Link> tarjoaa varmasti
          yhden kauneimmista paloista Suomea. Jo{" "}
          <Link to="/varsinaissuomi/turku">Turku</Link> itsessään on nähtävyys
          entisenä Suomen pääkaupunkina, mutta sitä ympäröivä saaristo jättää
          sanattomaksi kerta toisensa jälkeen. Saadaksesi lomastasi kaiken irti,
          vaatii tämä loma yöpymiseen vuokramökin sillä vain sillä tavalla
          pääset todella nukkumaan tämän upean luonnon keskelle. Ota siis
          suunnaksi Turku ja jatka sieltä lomaasi saaristoon, jossa ajankulu
          unohtuu ja sielu vain lepää. Mikäli matkassa on myös perheen pienimmä
          niin ei kannata myöskään unohtaa Naantalissa sijaitsevaa
          <a href="https://www.muumimaailma.fi/" rel="nofollow" target="_blank">
            Muumimaailmaa
          </a>
          , joka on varmasti unohtumaton kokemus koko perheelle.
        </p>
        <h4>Uusimaa</h4>
        <p>
          Lomakohdetta valitessa ei kannata unohtaa, että myös{" "}
          <Link to="/uusimaa">Uusimaa</Link> tarjoaa monenmoista tekemistä ja
          myös siellä vuokramökki on oikein mainio vaihtoehto majoittumiseen.
          Etenkin <Link to="/uusimaa/porvoo">Porvoon seutu</Link> tarjoaa
          monenmoista tekemista ja sen vanhaankaupunkiin ei voi kyllästyä.
          Mökistä käsin on myöskin helppo vierailla pääkaupungissamme
          Helsingissä ja pitkän päivän jälkeen palata levähtämään
          rauhallisempaan ympäristöön luonnon keskellä sijaitsevaan
          vuokramökkiin. Tällä tavalla saat lomastasi irti aivan uusia
          ulottuvuuksia. Mökkien löytäminen on helppoa sivustomme kautta ja näin
          ollen sinun tulee ainoastaan valita sinulle sopivin vaihtoehto ja
          lähteä nauttimaan lomasta jo vaikka ensi viikonloppuna.
        </p>
      </TextContainer>
    </GlobalStyle>
  )
}

export const query = graphql`
  query {
    file(base: { eq: "suomi.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMokitJson(limit: 50) {
      nodes {
        fields {
          city
          address
          people
          size
          price
        }
        Name
        TrackingUrl
        id
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: "frontpage" } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            filter
            img
            name
          }
        }
      }
    }
  }
`

export default IndexPage
